<template>
  <v-container fluid class="custom-bthrust-dashboard pt-0 width-90">
    <v-row>
      <v-col md="12">
        <v-layout class="dashboard-top-button">
          <v-flex class="mr-4">
            <router-link
              :to="getDefaultRoute('lead.create')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Lead</router-link
            >
          </v-flex>
          <v-flex class="mx-4">
            <router-link
              :to="getDefaultRoute('customer.create')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Customer</router-link
            >
          </v-flex>
          <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('quotation')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Quotation</router-link
            >
          </v-flex>
          <!-- <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('invoice')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Create Invoice</router-link
            >
          </v-flex>-->
          <v-flex class="ml-4">
            <router-link
              v-if="false"
              :to="getDefaultRoute('profile.setting')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18"
              >Settings</router-link
            >
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="4">
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 bg-danger header-one py-5">
            <h3 class="card-title font-weight-600 text-white">
              Quotation Stats
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <!--begin::Stats-->
            <div class="card-spacer mt-0">
              <!--begin::Row-->
              <div class="row m-0">
                <div
                  class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                >
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-warning">{{
                    counts.quotation.total
                  }}</span>
                  <a href="#" class="text-warning font-weight-500 font-size-h6"
                    >Total Quotation</a
                  >
                </div>
                <div class="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-primary">{{
                    counts.quotation.accepted
                  }}</span>
                  <a
                    href="#"
                    class="text-primary font-weight-500 font-size-h6 mt-2"
                    >Total Accepted</a
                  >
                </div>
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7 mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-danger d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-danger">{{
                    counts.quotation.rejected
                  }}</span>
                  <a href="#" class="text-danger font-weight-500 font-size-h6"
                    >Total Rejected</a
                  >
                </div>
                <div class="col bg-light-success px-6 py-8 rounded-xl mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-success">{{
                    counts.quotation.closed
                  }}</span>
                  <a
                    href="#"
                    class="text-success font-weight-500 font-size-h6 mt-2"
                    >Total Closed</a
                  >
                </div>
              </div>
              <!--end::Row-->
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Body-->
        </div>
      </v-col>
      <v-col md="4">
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 bg-danger header-two py-5">
            <h3 class="card-title font-weight-600 text-white">Project Stats</h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <!--begin::Stats-->
            <div class="card-spacer mt-0">
              <!--begin::Row-->
              <div class="row m-0">
                <div
                  class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                >
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-warning">{{
                    counts.project.total
                  }}</span>
                  <a href="#" class="text-warning font-weight-500 font-size-h6"
                    >Total Project</a
                  >
                </div>
                <div class="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-primary">{{
                    counts.project.inprogress
                  }}</span>
                  <a
                    href="#"
                    class="text-primary font-weight-500 font-size-h6 mt-2"
                    >Total In-Progress</a
                  >
                </div>
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7 mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-danger d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-danger">{{
                    counts.project.cancelled
                  }}</span>
                  <a
                    href="#"
                    class="text-danger font-weight-500 font-size-h6 mt-2"
                    >Total Cancelled</a
                  >
                </div>
                <div class="col bg-light-success px-6 py-8 rounded-xl mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Media/Equalizer.svg-->
                    <inline-svg
                      :src="$assetURL('media/svg/icons/Media/Equalizer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span class="dashboard-stat-count text-success">{{
                    counts.project.finished
                  }}</span>
                  <a href="#" class="text-success font-weight-500 font-size-h6"
                    >Total Finished</a
                  >
                </div>
              </div>
              <!--end::Row-->
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Body-->
        </div>
      </v-col>
      <v-col md="4">
        <ListWidget :data-list="user_history"></ListWidget>
      </v-col>
      <v-col v-if="false" md="4">
        <StatsWidget color="primary"></StatsWidget>
        <StatsWidget
          color="warning"
          title="Monthly Profit"
          value="36321"
        ></StatsWidget>
      </v-col>
      <v-col v-if="false" md="4">
        <MixedWidget
          :counts="counts.project"
          :chart="projectChart"
        ></MixedWidget>
      </v-col>
      <v-col md="12">
        <TableWidget
          v-on:create:quotation="createTransaction('quotation')"
        ></TableWidget>
      </v-col>
    </v-row>
    <CustomerDialog
      v-if="customerDialog"
      :customerDialog.sync="customerDialog"
      v-on:closeDialog="closeDialog"
      v-on:resetAll="resetAll"
      v-on:selectCustomer="selectCustomer"
      v-on:selectCustomerPerson="selectCustomerPerson"
      v-on:selectCustomerProperty="selectCustomerProperty"
      v-on:selectCustomerBilling="selectCustomerBilling"
    ></CustomerDialog>
    <CustomerPersonDialog
      v-if="customerPersonDialog"
      :customerPersonDialog.sync="customerPersonDialog"
      :customer.sync="customer"
      v-on:closeDialog="closeDialog"
      v-on:resetAll="resetAll"
      v-on:selectCustomerPerson="selectCustomerPerson"
    ></CustomerPersonDialog>
    <CustomerPropertyDialog
      v-if="customerPropertyDialog"
      :customerPropertyDialog.sync="customerPropertyDialog"
      :customer.sync="customer"
      v-on:closeDialog="closeDialog"
      v-on:resetAll="resetAll"
      v-on:selectCustomerProperty="selectCustomerProperty"
    ></CustomerPropertyDialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import MixedWidget from "@/view/content/widgets/mixed/Widget.vue";
import StatsWidget from "@/view/content/widgets/stats/Widget.vue";
import ListWidget from "@/view/content/widgets/list/Widget.vue";
import TableWidget from "@/view/content/widgets/advance-table/Widget.vue";
import { GET } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  name: "dashboard",
  data() {
    return {
      transactionType: null,
      customer: null,
      customerPerson: null,
      customerProperty: null,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      projectChart: {},
      user_history: [],
      counts: {
        quotation: {
          total: 0,
          accepted: 0,
          rejected: 0,
          closed: 0,
        },
        project: {
          total: 0,
          inprogress: 0,
          cancelled: 0,
          finished: 0,
          sum_total: 0,
          sum_in_progress: 0,
          sum_on_hold: 0,
          sum_finished: 0,
        },
        invoice: {
          total: 0,
          sent: 0,
          unpaid: 0,
          paid: 0,
        },
      },
    };
  },
  methods: {
    getCounts() {
      this.$store
        .dispatch(GET, { url: "dashboard/counts" })
        .then(({ data }) => {
          this.counts = {
            quotation: data.quotation,
            project: data.project,
            invoice: data.invoice,
          };
          this.user_history = data.histories;
          this.projectChart = {
            categories: data.categories,
            series: data.series,
          };
        })
        .catch((error) => {
          ErrorEventBus.$emit("update:error", InitializeError(error));
        });
    },
    createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();
      if (
        (!this.customerProperty || this.customerProperty <= 0) &&
        this.transactionType != "quotation"
      ) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType() {
      const _this = this;
      if (_this.transactionType === "quotation") {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerBilling > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                billing: _this.customerBilling,
              },
            })
          );
        }
      } else {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerProperty > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transactionType + ".create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                property: _this.customerProperty,
              },
            })
          );
        }
      }
    },
  },
  components: {
    ListWidget,
    TableWidget,
    MixedWidget,
    StatsWidget,
    CustomerDialog,
    CustomerPersonDialog,
    CustomerPropertyDialog,
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    const _this = this;
    _this.getCounts();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
  },
};
</script>
