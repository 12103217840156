<template>
  <b-dropdown
    size="sm"
    variant="primary"
    :toggle-class="btnClass"
    :text="btnText"
    style="min-height: 36.88px !important"
  >
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text
        v-for="(row, index) in list"
        :key="index"
        tag="div"
        class="navi-item"
        v-on:click="$emit('update:status', row.value)"
      >
        <a class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-graph-1"></i>
          </span>
          <span class="navi-text text-capitalize">{{ row.text }}</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
export default {
  name: "dropdown-4",
  props: {
    btnClass: String,
    btnText: String,
    list: Array,
  },
  components: {},
  mounted() {},
};
</script>
