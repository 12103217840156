<template>
  <!--begin::List Widget-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="font-weight-bolder text-dark"> My Activity </span>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-4">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 52vh; position: static"
      >
        <div class="timeline timeline-6 mt-3">
          <template v-for="(item, i) in rows">
            <!--begin::Item-->
            <div class="timeline-item align-items-start" v-bind:key="i">
              <!--begin::Label-->
              <div
                class="timeline-label font-weight-bolder text-dark-75 font-size-lg white-space-nowrap"
              >
                {{ formatDate(item.time) }}<br />{{ formatTime(item.time) }}
              </div>
              <!--end::Label-->

              <!--begin::Badge-->
              <div class="timeline-badge">
                <i class="icon-xl" v-bind:class="item.badge"></i>
              </div>
              <!--end::Badge-->

              <!--begin::Text-->
              <div
                class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
              >
                <span
                  v-bind:class="{
                    'font-weight-bolder text-dark-75': item.bold,
                  }"
                  v-html="item.desc"
                ></span>
              </div>
              <!--end::Text-->
            </div>
            <!--end::Item-->
          </template>
        </div>
      </perfect-scrollbar>
      <!--end: Items-->
    </div>
    <!--end: Card Body-->
  </div>
  <!--end: Card-->
  <!--end: List Widget-->
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "widget",
  mixins: [CommonMixin],
  data() {
    return {
      rows: [],
    };
  },
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    dataList: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      for (let i = 0; i < this.dataList.length; i++) {
        this.rows.push({
          time: this.dataList[i].added_at,
          desc: this.dataList[i].description,
          bold: true,
          badge: "fa fa-genderless text-success",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.init();
  },
};
</script>
